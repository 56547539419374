import Navbar from '../../components/Navbar'
import ProjectTitle from '../../components/projectpages/ProjectTitle'
import SandD from '../../components/projectpages/SandD'
import SandPandD from '../../components/projectpages/SandPandD'
import SkillsCustomCard from '../../components/projectpages/SkillsCustomCard'
import CarouselCard from '../../components/projectpages/CC'
import Footer from '../../components/Footer'

import React from 'react'
import { skillsDict } from '../../Data/SkillsCardData'

import explodedView from "../../assets/Projects/SteeringWheel/SWE.png"
import SW_FRONT from "../../assets/Projects/SteeringWheel/SW_FRONT_.png"
import SW_BACK from "../../assets/Projects/SteeringWheel/SW_BACK_.png"
import SW_ROUTED from "../../assets/Projects/SteeringWheel/SW_Routed_.png"
import schematic from "../../assets/Projects/SteeringWheel/schematic_final_.png"
import flowDiagram from "../../assets/Projects/SteeringWheel/software_engineering.png"
import steeringWheelGUI from "../../assets/Projects/SteeringWheel/steering_wheel_gui.png"


function SteeringWheel() {

    const imageList = [
        explodedView,
        SW_FRONT,
        SW_BACK,
        SW_ROUTED,
        schematic,
        flowDiagram,
        steeringWheelGUI,
    ]

    const skills = [
        skillsDict['altium'],
        skillsDict['cpp'],
        skillsDict['python'],
        skillsDict['solidworks'],
    ]

    const inputList = [
      'Walkie Talkie Activation (Push To Talk)',
      "Drag Reduction System (DRS)",
      "Engine Mode Selection",
      "DRS Mode Selection",
      "Shift Up",
      "Shift Down",
      "Clutch Actuation"
    ]

    const designObjectives = [
      "Decoupling Updating Input States From Transmit Actions",
      "Update The Input States Every 1 ms",
      "Transmit The Newest Input States Every 10 ms",
    ]
    
  return (
    <div>
        <Navbar/>

        <ProjectTitle 
          title="Racing Steering Wheel"
          // imgsrc={explodedView}
        />

        <SandD 
          title="Overview"
          p1="The objective of this project was to enhance the driving experience of the 2024 Duke FSAE race car by developing a cutting-edge racing steering wheel. The overarching goal of the project was to foster a heightened level of driver engagement and interaction with the vehicle through innovative steering wheel functionalities. "
          p2="My contributions to the project comprised: (1) Defining electrical interactions, encompassing aspects such as input selection and response mechanisms, (2) Developing a embedded system capable of communication with the car's integral systems over the CAN Bus, and (3) Designing and manufacturing a printed circuit board (PCB). These contributions collectively advanced the project toward its goal of creating a technologically advanced and immersive driving interface."
        />

        <SandPandD 
          title="Input Definiton"
          imgsrc={explodedView}
          p1="During the initial phase of the design process, we engaged in a thoughtful exploration centered around a fundamental question: What holds the utmost significance for a driver? Drawing insights from the feedback of past drivers and the anticipated functionality of the 2024 car, we curated the following input list:"
          bulletList={inputList}
          p2="With these outlined considerations, my role involved optimizing the electrical integration of these functions. In order to minimize the cognitive load on the driver during active driving, I chose to implement buttons for inputs deemed less critical to driving, including Push-to-Talk (PTT), DRS activation, Engine Mode selection, and DRS Mode Selection. In contrast, recognizing the criticality and frequency of shifting and clutch actuation, I decided to advance with paddle buttons for shifting operations and a hall effect-based paddle for clutch engagement. These choices aim to streamline the driver's interaction with the steering wheel, prioritizing ease and efficiency during the driving experience."
          floatImgs={true}
          />

        <SandPandD 
          title="Electrical Integration"
          imgsrc={schematic}
          img2src={SW_ROUTED}
          p1="Following the definition of the electrical components integral to the design, my next step involved utilizing Altium Designer to construct a schematic & PCB tailored for integration into the steering wheel."
          p2="The foundation of this PCB centers around the ESP32 C3 Xiao microcontroller, leveraging its built-in CAN bus controller for streamlined communication with the car's ECU and Dash via a minimal two-wire setup (outside of power). A major design choice was to incorporate quick connects, enabling the PCB to seamlessly attach to the back of buttons once mounted on the carbon-fibre front plate of the steering wheel. This overall design not only minimizes the electronic footprint within the steering wheel but also places a premium on reliability, ensuring the driver will not experience any unexpected reactions from the steering wheel when racing"
          floatImgs={true}
          reverse={true}
          />

        <SandPandD 
          title="Mechanical Integration"
          floatImgs={true}
          imgsrc={SW_FRONT}
          img2src={SW_BACK}
          p1="After completing the electrical design phase, the last in the pre-fabrication process involved seamlessly integrating the PCB with the body of the steering wheel, crafted by the chassis sub-team."
          p2="The primary objective was to minimize the need for additional mounting holes, thereby preserving the structural integrity and aesthetic coherence of the steering wheel assembly. To achieve this goal, I strategically utilized the existing mounting bolts, supplemented by the addition of two smaller PCB screws (that would not penetrate thought the carbon fibre base plate). This meticulous approach ensured a harmonious fusion of electronics and mechanical components in the final product"
        />

        <SandPandD
          title="Software Integration"
          imgsrc={flowDiagram}
          p1="In the culmination of this project, the objective was the development of a binary file compiled from C++. The overarching goal centered around crafting efficient code capable of optimizing the frequency of updates to the car's CAN network. To achieve this, the subproject was defined by the following design choices:"
          bulletList={designObjectives}
          p2="The seamless execution of the two tasks is guaranteed by the integration of FreeRTOS on the microcontroller, specifically leveraging the dual-core architecture of the ESP32. FreeRTOS efficiently distributes the tasks across the two cores, enabling parallel processing and optimal utilization of resources. This dual-core orchestration ensures that the input states are consistently updated every 1 millisecond, and the latest information is transmitted every 10 milliseconds. The preemptive multitasking nature of FreeRTOS, coupled with dual-core distribution, not only enhances system reliability and real-time responsiveness but also maximizes the overall efficiency of the microcontroller in handling complex and concurrent operations."
        />

        <SandPandD
          title="Testing & Custom GUI"
          imgsrc={steeringWheelGUI}
          p1="After integrating the electromechanical system with the steering wheel enclosure and finalizing the C++ code, the next crucial step was to test the design. Since the vehicle was not yet ready for integration, I created a Python-based graphical user interface (GUI) for testing."
          // bulletList={designObjectives}
          p2="This GUI enabled me to test all the inputs of the steering wheel, ensuring each component functioned correctly. Additionally, it allowed me to gather feedback on the responsiveness of the steering inputs from potential vehicle drivers. By simulating on-track driver usage, I was able to identify and address issues, enhancing the overall user experience and ensuring the steering system met the necessary performance standards. This approach also allowed me to complete the steering wheel project ahead of schedule, without being dependent on the physical readiness of the vehicle, allowing more time for alternative vehicle projects."
        />

        <SkillsCustomCard 
          skills={skills}
        />

        <CarouselCard
          imgList={imageList}
          floatImgs={true}
          />

        <Footer/>
    </div>
  )
}

export default SteeringWheel