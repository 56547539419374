import decibel_img from "../assets/decibel.png"
import powerbank_img from "../assets/Projects/PowerBank/power-bank.png"
import gearguroo_img from "../assets/Projects/GearGuroo/GearGuroo.png"
import skateboard_img from "../assets/skateboard.png"
import harness_img from "../assets/Projects/DynoHarness/harness.png"
import a_drs_cad from "../assets/Projects/A-DRS/a-drs-cad.png"
import dash_panel_cad from "../assets/Projects/DASH-PANEL/dp-cad.png"
import save_my_battery from "../assets/Projects/Save-My-Battery/device_open.jpg"
import steering_wheel from "../assets/Projects/SteeringWheel/steeringwheelfull.jpg"
import shift_assembly from "../assets/Projects/fsaeShifting/shift_assembly.jpg"
import sps from "../assets/Projects/SPS-Encoder/assembled-3d.png"

// import not_available_img from "../assets/unavailable.png"

// ADD Project Cards Here

const ProjectCardData = [
    
    {   title:  "Shift Control Strategy",
        imgsrc: shift_assembly,
        text:   "The goal of this project was to design and implement an electric shifting strategy to enhance the driver experience by simplifying the shifting process. By utilizing high-power servos and calibrating the control algorithm for throttle blips and fuel cuts, the average shift time was dramatically reduced from 1100 ms to 150 ms. This innovation significantly boosts transmission performance, contributing to the team's success by decreasing average lap times.",
        view:   "/projects/shift-control-strategy",
        source: "",
        date:   "February 2024 - May 2024"
    },
    {   title:  "Racing Steering Wheel",
        imgsrc: steering_wheel,
        text:   "The primary objective of this project was to implement a design that offers electrical customizability, enabling a driver to have a flexible range of inputs while minimizing the wiring complexity within the steering column. By combining CAN bus protocol, embedded system design and PCB modeling, the final design effectively doubled the number of inputs on the steering wheel. The final product contributes the teams success by optimizing performance and reliability.",
        view:   "/projects/racing-steering-wheel",
        source: "https://github.com/NateAsia/ESP32-RaceSteeringWheel",
        date:   "July 2023 - May 2024"
    },
    {   title:  "FSAE Drag Reduction System",
        imgsrc: a_drs_cad,
        text:   "This is a Duke Motorsports project where I collaborated with the aerodynamic sub-team to implement an embedded automatic drag reduction system. The primary goal was to enhance the aerodynamic efficiency of our FSAE car by implementing a system capable of autonomously reducing drag during critical racing moments. This project contributed to a 5% improvement in the car's lap time.",
        view:   "auto-drag-reduction-system",
        source: "https://github.com/NateAsia/ESP32-DRS",
        date:   "July 2023 - May 2024"
    },
    {   title:  "Dash Panel Controller",
        imgsrc: dash_panel_cad,
        text:   "In this project, I engineered an embedded controller to govern LED alert signals on the dashboard panel of an FSAE car. The primary goal was to furnish the driver with immediate and precise feedback on the car's status, utilizing the efficiency of the CAN bus for seamless communication between the embedded controller and various vehicle subsystems. This approach enhanced the driver's situational awareness and facilitated quick responses to critical information.",
        view:   "",
        source: "https://github.com/NateAsia/ESP32-DashAlertPanel",
        date:   "July 2023 - May 2024"
    },
    {   title:  "Steering Position Encoder",
        imgsrc: sps,
        text:   "Implemented a steering position encoder in an FSAE car to capture precise steering angle and acceleration data, enhancing our understanding of driver behavior and vehicle dynamics during racing maneuvers. This project revolutionizes driving style analysis, facilitating performance optimization and continuous improvement in our FSAE team's racing endeavors.",
        view:   "",
        source: "",
        date:   "February 2023",
    },
    {   title:  "Car Monitor",
        imgsrc: save_my_battery,                 // Get new image
        text:   "In this embedded system project, I engineered a device capable of using ambient temperature, battery voltage, and the vehicle CAN bus to promptly alert users to potential issues such as a depleted battery or car faults. This innovative solution not only enhances user convenience by providing real-time monitoring but also proactively addresses concerns related to vehicle performance and battery health.",
        view:   "",
        source: "",
        date:   "August - December 2023"
    },
    {   title:  "Electric Skateboard",
        imgsrc: skateboard_img,
        text:   "This was a personal project where I electrified a longboard for casual cruising around Duke's campus. The goal of this project was to reduce my reliance on public transportation between the residence halls and the main campus and therefore boosting my contributions to environmental sustainability.",
        view:   "",
        source: "https://github.com/NateAsia/ESK8Board",
        date:   "May - November 2023"
    },
    {   title:  "Custom ECU Testing Harness",
        imgsrc: harness_img,
        text:   "I designed, manufactured, and installed a custom wiring harness to test ECU configurations for a turbocharged Yamaha FZ-07 twin-cylinder engine used in an FSAE car. Utilizing Altium and RapidHarness, I planned and verified the construction of the harness. The successful installation of this project has enabled configuration changes while isolating the system from the vehicle chassis. As a result, the maximum horsepower increased from 20 hp to over 60 hp",
        view:   "",
        source: "",
        date:   "August 2022 - January 2023"
    },
    {   title:  "GearGuroo",
        imgsrc: gearguroo_img,
        text:   "Smart fitness device that trains users on the basics of riding a bike. This system uses the combination of a gyroscope and hall-effect sensor to provide our user gear recommendations based on their speed and angle",
        view:   "/projects/gearguroo",
        source: "https://github.com/NateAsia/GearGuroo",
        date:   "August - December 2022"
    },
    {   title:  "190Wh Power Bank",
        imgsrc: powerbank_img,
        text:   "This energy bank was created to provide power to mid-size devices (TVs, projectors, laptop chargers, etc) during the non-solar hours of Krzyzewskiville camping.",
        date:   "January - February 2022",
        hideBtns: true
    },
    {   title:  "Team Decibel Detection",
        imgsrc: decibel_img,
        text:   "In my first-year design course, our team engineered an ear protection device for Duke's student maker spaces. This device combines an omnidirectional microphone with an LED strip to indicate to nearby users when ear protection is needed. ",
        view:   "",
        source: "https://www.youtube.com/watch?v=Ac4866vmRBg",
        date:   "August - December 2020"
    },
]


export default ProjectCardData