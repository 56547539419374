import dums_logo from "../assets/logos/DUMs Logo.png"
import rubrikLogo from "../assets/logos/rubrik.png"
import comcastLogo from "../assets/logos/comcast_logo_icon_170338.png"

const ExperienceCardData = {
    "Duke Motorsports":{
        logo: dums_logo,
        job_title: "Lead Electrical Engineer",
        location: "Durham, NC",
        timeline: "August 2022 - May 2024",
        description: "At Duke Motorsports, I had the privilege of leading a multidisciplinary team of engineers in the comprehensive design, development, and management of the electrical and communication systems for our FSAE car. One of my notable achievements is the successful redesign and implementation of the car's Controller Area Network (CAN) configurations, resulting in a remarkable reduction of CAN bus load from 80% to a highly efficient 20%. Additionally, I played a pioneering role in the team's technological advancements by leading the development of our inaugural digital steering wheel. This innovative device utilizes an embedded system to encode driver inputs seamlessly over the CAN network, enhancing overall efficiency and control. Collaborating closely with the aerodynamic sub-team, I drove the implementation of an embedded automatic drag reduction system (A-DRS). This collaborative effort yields a predicted 5% improvement in race lap time."
    },
    "Rubrik": {
        logo: rubrikLogo,
        job_title: "Software Engineer",
        location: "Palo Alto, CA",
        timeline: "May - August 2023",
        description: "During my time at Rubrik, I spearheaded the transition from Phabricator to GitHub Pull Requests, overseeing design, execution, and maintaining internal verification systems. This initiative successfully optimized collaboration, code management, and developer efficiency. Notably, representing the DevOps team at an engineering all-hands meeting led to a significant increase in project adoption, soaring from 5% to 80%. This experience showcases my technical proficiency and ability to drive positive change within the organization.",
    },
    "Comcast":{
        logo: comcastLogo,
        job_title: "Product Manager",
        location: "Philadelphia, PA",
        timeline: "May - August 2022",
        description: "At Comcast, I was instrumental in coding Python packages to enhance video content with contextual metadata, driving the successful launch of VideoAI. Simultaneously, I led the research and development of new templates and guidelines for documenting product requirements. This initiative resulted in a remarkable 50% reduction in time spent on product meetings and sprint updates, showcasing my ability to deliver impactful solutions and streamline operational processes.",
    }

}



export default ExperienceCardData