import Navbar from "../../components/Navbar";
import ProjectTitle from "../../components/projectpages/ProjectTitle";
import SandD from "../../components/projectpages/SandD";
import SandPandD from "../../components/projectpages/SandPandD";
import SkillsCustomCard from "../../components/projectpages/SkillsCustomCard";
import CarouselCard from "../../components/projectpages/CC";
import Footer from "../../components/Footer";

import React from "react";
import { skillsDict } from "../../Data/SkillsCardData";

import dog_gear from "../../assets/Projects/fsaeShifting/dog.png";
import shift_assy_hand from "../../assets/Projects/fsaeShifting/shift_assembly.jpg";
import firmware_components from "../../assets/Projects/fsaeShifting/shift_firm_components.png";
import example_success from "../../assets/Projects/fsaeShifting/example-sucesss.png";
import test_skid from "../../assets/Projects/fsaeShifting/skid.jpg"
import test_lineup from "../../assets/Projects/fsaeShifting/lineup.jpg"

function ShiftControl() {
  const imageList = [
    shift_assy_hand,
    dog_gear,
    firmware_components,
    example_success,
    test_skid,
    test_lineup
  ];

  const skills = [skillsDict["solidworks"], skillsDict["cpp"]];

  const hpAcademyTakeaways = {
    "Mechanical Considerations":
      "In a traditional manual transmission, synchronizers are used to match the speed of the gears before they engage, requiring the use of the clutch to disconnect the engine from the transmission during shifting. However, in a sequential transmission with dog clutches, there are no synchronizers. Instead, the dogs engage directly with the gears. This design allows for very fast gear changes but requires precise throttle timing and coordination.",
    "Electronic Control":
      "To ensure consistent shifting times while accounting for the varying dynamics the engine and transmission experience during a race, the most effective shifting strategies use electronic control of the throttle body, ignition, and fuel. Achieving this requires several key components: shift request inputs (such as shift paddles), integration with the engine control unit (ECU), and sensory inputs for closed-loop control of the shifting process.",
    "Calibration and Testing":
      "Calibration of a shifting strategy is essential for optimal performance in high-performance or racing applications. Calibration ensures that torque management events (throttle blips, fuel/ignition cuts, etc.) occur at ideal moments within the shifting window while considering the variables of engine speed, wheel speed, and vehicle acceleration.  Extensive testing under various conditions validates the shift system's performance and identifies issues. Analyzing the collected data allows for fine-tuning timing tables, recalibrating sensors, and refining control algorithms. Continuous recalibration and testing lead to an optimized shifting strategy that enhances performance, reliability, and driver experience",
  };

  const electricDevices = {
    ETB: "The electronic throttle body is critical for autonomously controlling the behavior of the engine. By placing the throttle input under electronic control, the system can precisely blip the throttle to increase engine speed without causing unintended acceleration of the vehicle.",
    "Ignition Coils & Spark Plugs":
      "All internal combustion engines (aside from diesel engines) require ignition coils and spark plugs to initiate combustion in the cylinders. Leveraging electronic control of the ignition allows the system to effectively manage the immediate torque produced by the engine, especially useful during an upshift when engine speed needs to drop quickly to synchronize the drive shaft speed with the output shaft speed of the next gear.",
    "Fuel Injectors":
      "Like ignition coils and spark plugs, many modern engines use fuel injectors. They are used to precisely deliver fuel into each cylinder. By coordinating the control of fuel injectors with ignition coils, engine speed can be precisely managed without leaving excess fuel in the exhaust, thereby preserving the powertrain's integrity.",
    "High Torque Servos":
      "High-power servos are used to electronically actuate the gearbox. This allows for precise timing of shift control events (throttle blips and ignition/fuel cuts) with the engagement of a new gear.",
    "Wheel Speed Sensors":
      "Wheel speed sensors are essential for determining driveshaft speed. This information enables the system to calculate the required engine output shaft speed to safely engage a new gear.",
    "Engine Speed Sensor":
      "Engine speed sensors are crucial for the closed-loop control of matching the output shaft speed to the driveshaft speed, similar to wheel speed sensors.",
    "Fork Position Sensor":
      "The fork is the external component of the gearbox that rotationally translates when attempting to shift. A fork position sensor was introduced to detect if throttle blips are acceptable for a given shift request. This adds a layer of redundancy, ensuring no unintended acceleration occurs in the event of a mechanical failure.",
    "Shift Paddles":
      "The shifting paddles are the driver inputs responsible for informing the central control system that an up or downshift is requested",
    ECU: "The engine control unit is the most important component of the system. Acting as the system's brain, it takes in all sensory inputs, performs real-time calculations, and provides closed-loop control of all system actuators.",
  };

  const controlStrategyComponents = {
    "Servo Control":
      "The original shifting firmware was built for a solenoid or compressed air system, which did not accommodate using servo actuators. To address this limitation, I developed a new firmware component that utilizes the available half-bridge outputs on the ECU device to engage the servos for shifting requests.",
    "Actuation Detection":
      "The original firmware assumed every shift request was valid for a throttle blip or fuel cut, a reasonable assumption in race applications. However, Formula Student rules mandate that the shift control strategy must detect potential shift failures before applying throttle blips to prevent unintended acceleration. To comply with this, I implemented an actuation detection firmware component. This component monitors changes in a hall effect sensor, which detects the movement of the shifting fork, and uses the series of state changes to directly enable or disable throttle blipping for each shift.",
  };

  const testingSteps = {
    "Pre-Test Setup":
      "(1) Verify the integrity of all electronic connections and components. (2) Check vehicle fluids and general safety checklist. (3) Ensure calibration equipment and software are ready",
    "Vehicle Shakedown Drive":
      "Conduct an initial drive to identify any immediate issues or irregularities in vehicle performance. This typically includes a light drive and a mini skidpad run",
    "On-Throttle upshifts":
      "Perform upshifts while the vehicle is under throttle to capture the responsiveness of gear changes",
    "On-Throttle downshifts": "Execute downshifts under throttle",
    "Off-Throttle upshifts": "Test upshifting with no throttle application",
    "Off-Throttle downshifts":
      "Perform downshifts with the throttle disengaged",
    "Data Collection":
      "Capture all relevant data from sensors, control systems, and driver feedback during testing",
    "Data Analysis":
      "Analyze collected data to identify trends, anomalies, and areas for improvement",
    "Configuration Adjustments":
      "Adjust control parameters based on the analysis",
    "Rerun or End Testing":
      "Conduct further tests to validate adjustments (back to step 2) or conclude the testing for the day",
  };

  const reflectionItems = {
    Solenoid:
      "Using solenoids instead of servo actuators to decrease response time",
    "RPM Control Exploration":
      "Experimenting more with different modes of RPM control. While this year's system leveraged fuel cuts and throttle blips to ensure powertrain safety, future iterations could benefit from incorporating ignition cuts and timing adjustments.",
  };

  return (
    <div>
      <Navbar />

      <ProjectTitle title="Shift Control Strategy" />

      <SandD
        title="Overview"
        p1="In this project, the average shift time for the Duke Motorsports FSAE car was reduced from 1200ms to 150ms."
        p2="This project builds on the 2023 decision to transition from hand-actuated shifting to electronic shifting, with the team focusing on using servos to actuate the vehicle's shifting fork. Analysis of the 2022-23 driving season revealed that the average shift time exceeded one second, significantly affecting our acceleration, autocross, and endurance lap times."
        p3="The primary objective for the 2023-24 season was to reduce lap times by minimizing the duration spent shifting between gears. To achieve this, I redefined the shifting strategy to enable clutchless shifting."
        p4="My involvement in the project included: (1) Investigating techniques to decrease shifting time in a Yamaha FZ-07 engine, (2) Integrating the electromechanical system needed for control, and (3) Redefining the ECU firmware with a new control strategy capable of safely enabling throttle blips and fuel cuts."
      />

      <SandPandD
        title="Research: Automotive Shifting Mechanics"
        imgsrc={dog_gear}
        img2src={shift_assy_hand}
        p1="To better understand how to approach the problem of decreasing shift time, I leveraged the following material from High Performance Academy."
        link={[
          "HP ACADEMY | WEBINAR 200 | CONFIGURING PADDLE SHIFT CONTROL",
          "https://www.hpacademy.com/previous-webinars/200-configuring-paddle-shift-control",
        ]}
        p2="In this webinar, I learned about the critical factors that influence shift times. Here are the key takeaways:"
        numList={hpAcademyTakeaways}
        p3="Understanding and implementing these principles was crucial for ensuring reliable, fast, and precise gear changes."
        floatImgs={true}
      />

      <SandD
        title="Electromechanical Integration"
        p1="After analyzing the requirements and problem space for developing effective shifting strategies, I compiled a list of electromechanical devices essential for providing the necessary sensory inputs, actuation outputs, and control for the shifting system"
        p2="The following items were identified as critical for the successful implementation of the shift strategy while also adhering to SAE safety requirements."
        numList={electricDevices}

        // p3="Together, these devices would covert the complette  shifting system, from a driver shift request to actuation control, to position validation"
        //   reverse={true}
      />

      <SandPandD
        imgsrc={firmware_components}
        img2src={example_success}
        title="Control Strategy & MoTeC Modifications"
        p1="Having selected the electronics, the next phase of the project involved developing a control strategy that seamlessly integrates all components while ensuring full compliance with FSAE regulations."
        link={[
          "FORMULA SAE RULES | 2024",
          "https://www.fsaeonline.com/cdsweb/app/NewsItem.aspx?NewsItemID=4af3d94c-748b-40e9-b5ed-dddea428a08b",
        ]}
        p2="The following are a list of the components leveraged or added to the ECU firmware to allow successful and safe shifting."
        numList={controlStrategyComponents}
        p3="By integrating these changes with the existing MoTeC firmware for shifting, I developed a control strategy that can swiftly respond to shift requests while preventing unintended acceleration, thereby ensuring the safety of the driver and race participants. Additionally, this approach paves the way for configurable shift timing to minimize time spent off the acceleration pedal, thereby enhancing overall performance."
        reverse={true}
        floatImgs={true}
      />

      <SandPandD
        title="Calibration and Testing"
        imgsrc={test_lineup}
        img2src={test_skid}
        p1="Following an initial verification to ensure successful integration of the control system and electronics, the most critical phase of the project commenced: the calibration and testing loop. In the absence of a chassis dynamometer, the team utilized an alternative method to calibrate and test the system. Specifically, the calibration process involved a driver operating the vehicle while team members, equipped with laptops, adjusted parameters in real-time."
        p2="To maintain efficiency and order throughout the calibration and testing activities, the team adhered to the following standardized procedure for each day of parameter adjustment:"
        numList={testingSteps}
        p3="By meticulously following these steps, my team and I were able to iteratively fine-tune the characteristics of the shifting strategy. This systematic approach allowed us to identify and rectify potential issues early on, optimize performance parameters, and ensure robust and reliable operation under various driving conditions."
      />

      <SandD
        title="Results"
        p1="The implementation of this shifting strategy had a significant impact on overall shift times, reducing them from 1200ms to 150ms. This improvement contributed to a reduction in our acceleration event time from 4.8s to 4.5s, boosting the team's competition score."
        p2="Furthermore, our drivers reported smoother and quicker gear transitions, underlining an enhanced driving experience."
      />

      <SandD
        title="Reflection"
        p1="This project taught me invaluable lessons in control systems, firmware development, and data-driven performance optimization. It also underscored the importance of clear communication and teamwork in successfully achieving complex engineering goals. These experiences have prepared me to tackle future engineering challenges with confidence and precision."
        p2="Reflecting on the project, if given the opportunity to redo this work, I would consider the following enhancements:"
        numList={reflectionItems}
        p3="These enhancements could enable our system to match or even surpass the transmission and shifting speeds of today's top performance vehicles, achieving a range of 5 to 20 milliseconds per shift."
      />

      <SkillsCustomCard skills={skills} />

      <CarouselCard imgList={imageList} floatImgs={true} />

      <Footer />
    </div>
  );
}

export default ShiftControl;
