import "./SandDStyles.css";
import React, { Component } from "react";

class SandD extends Component {
  render() {
    return (
      <section id={this.props.title}>
        <div className=" project-sd card card-limit">
          <div className="contents">
            <h2>{this.props.title}</h2>
            <p>{this.props.p1}</p>
            {this.props.bulletList ? (
              <ul>
                {this.props.bulletList.map((listItem) => (
                  <li>{listItem}</li>
                ))}
              </ul>
            ) : (
              <div />
            )}
            {this.props.p2 ? (
              <p className="double_para">{this.props.p2} </p>
            ) : (
              <div />
            )}
            {this.props.numList ? (
              <ol>
                {Object.entries(this.props.numList).map(([label, listItem]) => (
                  <li>
                    {label}: <span>{listItem}</span>
                  </li>
                ))}
              </ol>
            ) : (
              <div />
            )}
            {this.props.p3 ? (
              <p className="double_para">{this.props.p3} </p>
            ) : (
              <div />
            )}
            {this.props.p4 ? (
              <p className="double_para">{this.props.p4} </p>
            ) : (
              <div />
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default SandD;
